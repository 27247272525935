<!--
  非法人组织入驻申请
 -->
<template>
  <div class="detail page_min_width">
    <detail-header title="非法人组织入驻申请" />
    <div class="detail_main page_width" v-loading="loading">
      <div class="main_content">
        <el-form
          ref="elForm"
          :model="form"
          :rules="rules"
          size="small"
          label-width="130px"
          label-position="left">
          <div class="module_box">
            <div class="module_box_header">
              <p>机构信息</p>
            </div>
            <div class="module_box_main">
              <!-- 头像 -> 机构Logo -->
              <el-form-item
                label="机构头像"
                prop="avatarUrl">
                <upload-files
                  :showFileList="false"
                  :maxSize="4"
                  accept="image/png, image/jpg, image/jpeg"
                  :readonly="true"
                  :showTip="false"
                  listType="custom"
                  customClassName="logourl_upload"
                  @successUpload="(file, res) => form.avatarUrl = res.data.url"
                  title="点击上传">
                  <el-image
                    v-if="form.avatarUrl"
                    style="width: 120px; height: 120px;"
                    :src="form.avatarUrl"
                    fit="cover">
                  </el-image>
                  <div v-else class="uploader-icon">
                    <i class="el-icon-plus"></i>
                  </div>
                </upload-files>
              </el-form-item>
              <el-row type="flex" :gutter="70">
                <el-col>
                  <el-form-item
                    label="机构名称"
                    prop="name">
                    <el-input maxlength="50" show-word-limit
                      style="width: 100%;"
                      v-model="form.name"
                      placeholder="请填写组织全称">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col>
                  <el-form-item
                    label="负责人姓名"
                    prop="personCharge">
                    <el-input maxlength="50" show-word-limit
                      style="width: 100%;"
                      v-model="form.personCharge"
                      placeholder="请填写负责人姓名">
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row type="flex" :gutter="70">
                <el-col>
                  <el-form-item
                    label="身份证号"
                    prop="idCard">
                    <el-input maxlength="50" show-word-limit
                      style="width: 100%;"
                      v-model="form.idCard"
                      placeholder="请填写身份证号">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col>
                  <el-form-item
                    label="最高学历"
                    prop="highestEducation">
                    <el-input maxlength="50" show-word-limit
                      v-model="form.highestEducation"
                      placeholder="请输入最高学历">
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <!-- 去掉了组织类别 新增行业领域 -->
              <el-form-item
                label="行业领域"
                prop="serviceTrade">
                <el-checkbox-group v-model="form.serviceTrade">
                  <div class="checkbox_wrapper" style="width: 750px;">
                    <div>
                      <el-checkbox
                        v-for="item in industrySectorList"
                        :key="item.id"
                        :label="item.id">
                        {{ item.name }}
                      </el-checkbox>
                    </div>
                  </div>
                </el-checkbox-group>
              </el-form-item>
              <el-form-item
                label="服务方向"
                prop="serviceDirection">
                <el-checkbox-group v-model="form.serviceDirection">
                  <div class="checkbox_wrapper" style="width: 750px;">
                    <div>
                      <el-checkbox
                        v-for="item in staticData.fwfx"
                        :key="item"
                        :label="item">
                        {{ item }}
                      </el-checkbox>
                    </div>
                  </div>
                </el-checkbox-group>
              </el-form-item>
              <el-form-item
                label="产业特长"
                prop="industryExpertise">
                <el-checkbox-group v-model="form.industryExpertise">
                  <div class="checkbox_wrapper" style="width: 750px;">
                    <div>
                      <el-checkbox
                        v-for="item in technicalList"
                        :key="item.id"
                        :label="item.id">
                        {{ item.name }}
                      </el-checkbox>
                    </div>
                  </div>
                </el-checkbox-group>
              </el-form-item>
              <!-- 简介 -> 机构简介 -->
              <el-form-item
                label="机构简介"
                prop="description">
                <el-input
                  style="width: 100%;"
                  type="textarea" maxlength="300" show-word-limit
                  :rows="4"
                  placeholder="所从事的主营业务范围，团队介绍，所获得的成果和荣誉等"
                  v-model="form.description">
                </el-input>
              </el-form-item>
              <el-form-item
                label="机构服务案例"
                prop="serviceCase">
                <el-input
                  style="width: 100%;"
                  type="textarea" maxlength="300" show-word-limit
                  :rows="4"
                  placeholder="机构典型服务案例1-2个"
                  v-model="form.serviceCase">
                </el-input>
              </el-form-item>
              <el-form-item
                label="职称/资格认证"
                prop="positionalTitleText">
                <el-input
                  style="width: 100%;"
                  type="textarea" maxlength="300" show-word-limit
                  :rows="4"
                  placeholder="请输入负责人及团队主要成员职称/资格认证"
                  v-model="form.positionalTitleText">
                </el-input>
              </el-form-item>
            </div>
          </div>
          <div class="module_box">
            <div class="module_box_header">
              <p>其他信息</p>
            </div>
            <div class="module_box_main">
              <el-row type="flex" :gutter="70">
                <el-col>
                  <el-form-item
                    label="联系人"
                    prop="username">
                    <el-input maxlength="50" show-word-limit
                      style="width: 100%;"
                      v-model="form.username"
                      placeholder="填写联系人全名">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col>
                  <el-form-item
                    label="联系人性别"
                    prop="gender">
                    <el-radio-group v-model="form.gender">
                      <el-radio :label="1">男</el-radio>
                      <el-radio :label="2">女</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row type="flex" :gutter="70">
                <el-col>
                 <el-form-item
                    label="联系电话"
                    prop="contactNumber">
                    <el-input maxlength="11" show-word-limit
                      style="width: 100%;"
                      v-model="form.contactNumber"
                      placeholder="请输入联系电话">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col>
                  <el-form-item
                    label="联系邮箱"
                    prop="contactEmail">
                    <el-input maxlength="50" show-word-limit
                      style="width: 100%;"
                      v-model="form.contactEmail"
                      placeholder="请输入联系邮箱">
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row type="flex" :gutter="70">
                <el-col>
                 <el-form-item
                    label="联系地址"
                    prop="contactAddressArray">
                    <el-cascader
                      style="width: 100%;"
                      placeholder="请选择联系地址"
                      v-model="form.contactAddressArray"
                      :options="staticData.cities">
                    </el-cascader>
                    <el-input maxlength="50" show-word-limit
                      style="width: 100%; margin-top:10px;"
                      v-model="form.contactAddress"
                      placeholder="请输入详细地址">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col>
                  <el-form-item
                    label="人员情况">
                    <el-row type="flex">
                      <el-form-item
                        label-width="185px"
                        label="总人数"
                        prop="educationSituation.zongrenshu">
                        <el-input maxlength="50" show-word-limit
                          v-model.number="form.educationSituation.zongrenshu"
                          placeholder="请输入团队总人数">
                        </el-input>
                      </el-form-item>
                    </el-row>
                    <el-row type="flex">
                      <el-form-item
                        label-width="185px"
                        label="硕士（含以上）"
                        prop="educationSituation.shuoshi">
                        <el-input maxlength="50" show-word-limit
                          v-model.number="form.educationSituation.shuoshi"
                          placeholder="请输入团队硕士（含以上）人数">
                        </el-input>
                      </el-form-item>
                    </el-row>
                    <el-row type="flex">
                      <el-form-item
                        label-width="185px"
                        label="本科"
                        prop="educationSituation.benke">
                        <el-input maxlength="50" show-word-limit
                          v-model.number="form.educationSituation.benke"
                          placeholder="请输入团队本科人数">
                        </el-input>
                      </el-form-item>
                    </el-row>
                    <el-row type="flex">
                      <el-form-item
                        label-width="185px"
                        label="大专（及以下）"
                        prop="educationSituation.dazhuan">
                        <el-input maxlength="50" show-word-limit
                          v-model.number="form.educationSituation.dazhuan"
                          placeholder="请输入团队大专（及以下）人数">
                        </el-input>
                      </el-form-item>
                    </el-row>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-form>

        <div class="btn_box tw-mt-30px">
          <el-button
            style="margin-right: 20px;"
            type="primary"
            size="small"
            @click="submitForm"
            :loading="btnLoading"
            :disabled="!agree">
            提交申请
          </el-button>
          <el-checkbox v-model="agree">
            <p class="tw-text-14px tw-text-primary-text">已仔细阅读并同意
              <a :href="`${publicPath}诚信承诺书.pdf`" target="_blank">《诚信承诺书》</a>
            </p>
          </el-checkbox>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from '@/api'

export default {
  data () {
    // 验证详细地址是否输入
    const contactAddress = (rule, value, callback) => {
      if (this.form.contactAddress === '') {
        callback(new Error('请输入详细地址'))
      } else {
        callback()
      }
    }
    return {
      // 页面loading
      loading: false,
      btnLoading: false,
      agree: false, // 是否同意条款
      /* form: {
        name: '1738555',
        personCharge: '1738555赵',
        serviceTrade: [
          '512871474dbc67ba777986caaded0811',
          '6b60202442660694c56ebf0b9ca47fde',
          'deb94bb3a74cee4b108fcf7150590c90',
          'b336b1de4e00a5619405ce424c334ad1',
          '060fd548136fa09093f71a3ab951a61c'
        ],
        serviceDirection: [
          '科技咨询',
          '科学技术普及',
          '创业孵化'
        ],
        industryExpertise: [
          '29056a0eee037b4dd9b1c59b1bab8aff',
          'd14cf608d101f2ccb256a2fee9091436'
        ],
        description: '非常弱小',
        avatarUrl: 'http://192.168.1.175:9919/media/fa646d30300ca500e2339e0b9d1fc7d9',
        username: '1738555思',
        idCard: '420922199710136815',
        highestEducation: '本科',
        contactNumber: '17371278555',
        contactEmail: '1738555@qq.com',
        contactAddressArray: [
          '130000',
          '130300',
          '130302'
        ],
        contactAddress: '十里敦',
        gender: 2,
        serviceCase: '没有',
        educationSituation: {
          zongrenshu: 20,
          shuoshi: 30,
          benke: 40,
          dazhuan: 50
        },
        positionalTitleText: ''
      }, */
      form: {
        name: '', // 机构名称
        personCharge: '', // 负责人姓名
        // orgCategory: '', // 组织类别
        serviceTrade: [], // 行业领域
        serviceDirection: [], // 服务方向
        industryExpertise: [], // 产业特长
        description: '', // 机构简介

        // 用户信息
        avatarUrl: '', // 机构头像
        username: '', // 联系人
        // identityCategory: '', // 身份类别
        // identityCategoryOther: '', // 身份类别，其他
        idCard: '', // 用户身份证号
        // birthDate: '', // 出生年月
        // workUnit: '', // 工作单位
        // jobTitle: '', // 职务
        highestEducation: '', // 最高学历
        contactNumber: '', // 联系电话
        contactEmail: '', // 联系邮箱
        contactAddressArray: [], // 联系地址，省市区选择器
        contactAddress: '', // 联系地址详细地址
        gender: '', // 性别
        serviceCase: '', // 服务案例
        // personalProfile: '', // 个人简介
        educationSituation: {
          zongrenshu: 0, // 总人数
          shuoshi: 0, // 硕士（含以上）
          benke: 0, // 本科
          dazhuan: 0 // 大专
        },
        positionalTitleText: '' // 职称/所或资格认证
      },
      rules: {
        avatarUrl: [
          { required: true, message: '请上传机构头像', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '请输入机构名称', trigger: 'blur' }
        ],
        personCharge: [
          { required: true, message: '请输入机构负责人姓名', trigger: 'blur' }
        ],
        contactEmail: [
          // { required: true, message: '请输入联系邮箱', trigger: 'blur' }
          { pattern: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/, message: '请输入正确邮箱格式', trigger: 'blur' }
        ],
        contactNumber: [
          { required: true, message: '请输入联系电话', trigger: 'blur' }
        ],
        gender: [
          { required: true, message: '请选择联系人性别', trigger: 'change' }
        ],
        contactAddressArray: [
          { required: true, message: '请选择联系地址', trigger: 'change' },
          { validator: contactAddress, trigger: 'blur' }
        ],
        // contactAddress: [
        //   { required: true, message: '请输入联系人地址', trigger: 'blur' }
        // ],
        serviceTrade: [
          { required: true, message: '请选择行业领域', trigger: 'change' }
        ],
        industryExpertise: [
          { required: true, message: '请选择产业特长', trigger: 'change' }
        ],
        description: [
          { required: true, message: '请输入机构简介', trigger: 'blur' }
        ],
        serviceDirection: [
          { required: true, message: '请选择服务方向', trigger: 'change' }
        ],
        // orgCategory: [
        //   { required: true, message: '请选择组织类别', trigger: 'change' }
        // ],
        username: [
          { required: true, message: '请填写联系人全名', trigger: 'blur' }
        ],
        idCard: [
          // { required: true, message: '请填写身份证号', trigger: 'blur' }
          { pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/, message: '请输入正确身份证格式', trigger: 'blur' }
        ],
        // 各学历人数
        educationSituation: {
          zongrenshu: [
            // { required: true, message: '请填写身份证号', trigger: 'blur' }
            { pattern: /^(([1-9][0-9]*)|[0])$/, message: '请输入整数', trigger: 'blur' }
          ],
          shuoshi: [
            // { required: true, message: '请填写身份证号', trigger: 'blur' }
            { pattern: /^(([1-9][0-9]*)|[0])$/, message: '请输入整数', trigger: 'blur' }
          ],
          benke: [
            // { required: true, message: '请填写身份证号', trigger: 'blur' }
            { pattern: /^(([1-9][0-9]*)|[0])$/, message: '请输入整数', trigger: 'blur' }
          ],
          dazhuan: [
            // { required: true, message: '请填写身份证号', trigger: 'blur' }
            { pattern: /^(([1-9][0-9]*)|[0])$/, message: '请输入整数', trigger: 'blur' }
          ]
        },
        // birthDate: [
        //   { required: true, message: '请选择出生年月', trigger: 'change' }
        // ],
        // positionalTitle: [
        //   { required: true, message: '请上传职称/所获资格认证', trigger: 'blur' }
        // ],
        // identityCategory: [
        //   // { required: true, message: '请选择身份类别', trigger: 'change' },
        //   { validator: identityCategoryOther, trigger: 'blur' }
        // ]
        // workUnit: [
        //   { required: true, message: '请填写工作单位全称；如无，请填无', trigger: 'blur' }
        // ],
        // jobTitle: [
        //   { required: true, message: '请填写职务', trigger: 'blur' }
        // ],
        serviceCase: [
          { required: true, message: '请填写机构服务案例', trigger: 'blur' }
        ]
        // personalProfile: [
        //   { required: true, message: '请填写个人简介', trigger: 'blur' }
        // ],
        // highestEducation: [
        //   { required: true, message: '请填写最高学历', trigger: 'blur' }
        // ]
      }
    }
  },
  computed: {
    // 技术领域
    technicalList () {
      return this.$store.state.technicalList
    },
    // 行业领域下拉列表
    industrySectorList () {
      return this.$store.state.industrySectorList
    },
    // 开放目录
    publicPath () {
      return process.env.BASE_URL
    }
  },
  methods: {
    // 表单验证
    submitForm () {
      this.$refs.elForm.validate((valid) => {
        if (valid) {
          // 申请服务机构
          this.uncorporateRegister()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 非法人机构入驻
    uncorporateRegister () {
      let { serviceTrade, serviceDirection, industryExpertise, educationSituation, contactAddressArray } = this.form
      // 行业领域转为逗号分隔的字符串
      serviceTrade = serviceTrade ? serviceTrade.join(',') : ''
      // 服务方向转为逗号分隔字符串
      serviceDirection = serviceDirection ? serviceDirection.join(',') : ''
      // 产业特长转为逗号分隔字符串
      industryExpertise = industryExpertise ? industryExpertise.join(',') : ''
      // 人员情况转为逗号分割
      educationSituation = Object.values(educationSituation).join(',')
      const [addressProvince, addressCity, addressCounty] = [...contactAddressArray]
      const formData = {
        ...this.form,
        serviceTrade,
        serviceDirection,
        industryExpertise,
        educationSituation,
        addressProvince,
        addressCity,
        addressCounty
      }

      this.btnLoading = true
      api.uncorporateRegister({
        ...formData
      }).then(res => {
        if (res.data.code === 0) {
          this.$message.success('申请成功')
          this.$router.replace('/personal-info')
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        this.btnLoading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.detail {
  .detail_main {
    padding: 30px 0px;
    .main_content {
      width: 1100px;
      margin: 0 auto;
      .module_box {
        .module_box_header {
          display: flex;
          align-items: center;
          height: 40px;
          margin-bottom: 20px;
          &::before {
            content: "";
            width: 3px;
            height: 20px;
            background: #3473E6;
            border-radius: 1px;
            margin-right: 10px;
          }
          & > p {
            font-size: 16px;
            font-weight: bold;
            line-height: 22px;
            color: #31394C;
          }
        }
        .module_box_main {
          padding: 0 50px 0 20px;
        }
        &+.module_box {
          margin-top: 40px;
        }
      }
      .btn_box {
        display: flex;
        align-items: center;
        padding-left: 150px;

      }
      .logourl_upload {
        position: relative;
        overflow: hidden;
        transition: all 0.3s ease;
        .uploader-icon {
          width: 120px;
          height: 120px;
          background-color: #fbfdff;
          border: 1px dashed #c0ccda;
          border-radius: 6px;
          cursor: pointer;
          line-height: 120px;
          vertical-align: top;
          &:hover {
            border-color: $color-primary;
          }
          & i {
            font-size: 28px;
            color: #8c939d;
          }
        }
      }
      .codeurl_upload {
        position: relative;
        overflow: hidden;
        transition: all 0.3s ease;
        .uploader_box {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 350px;
          height: 220px;
          background-color: #fbfdff;
          border: 1px dashed #c0ccda;
          border-radius: 6px;
          cursor: pointer;
          line-height: 120px;
          vertical-align: top;
          &:hover {
            border-color: $color-primary;
          }
          & > i {
            font-size: 40px;
            color: #9AA0A9;
          }
          & > p {
            width: 200px;
            font-size: 14px;
            line-height: 20px;
            color: #909399;
            margin-top: 15px;
          }
        }
      }
      .checkbox_wrapper {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 450px;
      }
    }
  }
}
</style>
